<template>
    <div class="leave_message">
        <header></header>
        <section></section>
        <footer></footer>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    .leave_message{
          
    }
</style>